import React, { useState } from 'react';
import { useError } from '../../contexts/ErrorContext'; // Import the useError hook
import { apiUrls } from "../../utils/apiUrls";
import { callAPI } from "../../utils/apiUtils";
import styles from './Login.module.css';


const Login = ({ onLogin }) => {
  const [step, setStep] = useState(1); // Step 1: Email, Step 2: OTP
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [otp, setOtp] = useState('');
  const { triggerError } = useError();  // Access the triggerError function from ErrorContext
  const [loginSession, setLoginSession] = useState('');

  const handleEmailSubmit = async () => {
    if (!email) {
      triggerError('Please enter a valid email address.');
      return;
    }
    setLoading(true);
    
    try {
      // Call the API using the custom hook
      const formData = new FormData();
      formData.append("email", email);
      const headers = {
        "Content-Type": "application/json",
     }
      const response = await callAPI(apiUrls.ADMINCOGNITOOGIN, {}, "POST",formData,{},headers);
      if (response.data.sentStatus === "SENT") {
        setLoginSession(response.data.session)
        setStep(2); // Move to OTP step
      } else {
        triggerError(response.data.message);
      }
    } catch (error) {
      console.error('Error during email submission:', error);
      triggerError('Failed to submit email. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const handleOtpSubmit = async (e) => {
    console.log("ConfirmCodeLogin")
    e.preventDefault();
    try {
       if (!email) {
          triggerError('Please enter email.');
       }  else if (!otp) {
          triggerError('Please enter the OTP.');
       } else {
          const formData = new FormData();
          formData.append("email", email);
          formData.append("code", otp);
          formData.append("session", loginSession);
                const headers = {
             "Content-Type": "application/json",
          }
          const response = await callAPI(apiUrls.ADMINCOGNITOVERIFY, {}, "POST",formData,{},headers);
          console.log(response);
          if (response.data.status === "LOGIN") {
             localStorage.setItem("userId", response.data.userId)
             localStorage.setItem("accessToken", response.data.tokens.AccessToken)
             localStorage.setItem("idToken", response.data.tokens.IdToken)
             localStorage.setItem("refreshToken", response.data.tokens.RefreshToken)
             onLogin(); // Call handleLogin function passed from parent component
             window.history.replaceState(null, "", "/");
          } else if(response.data.status === "INVALID_CODE") {
             setLoginSession(response.data.session)
             triggerError(response.data.message)
          } else {
             triggerError(response.data.message)
          }


       }
    } catch (error) {
       console.error('An error occurred:', error);
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.card}>
        {step === 1 ? (
          <>
            <h1>Login</h1>
            <input
              type="email"
              placeholder="Enter your email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className={styles.input}
            />
            <button
              onClick={handleEmailSubmit}
              className={styles.button}
              disabled={loading}
            >
              {loading ? 'Submitting...' : 'Submit Email'}
            </button>
          </>
        ) : (
          <>
            <h1>Verify OTP</h1>
            <input
              type="text"
              placeholder="Enter the OTP"
              value={otp}
              onChange={(e) => setOtp(e.target.value)}
              className={styles.input}
            />
            <button
              onClick={handleOtpSubmit}
              className={styles.button}
              disabled={loading}
            >
              Submit OTP
            </button>
          </>
        )}
      </div>
    </div>
  );
};

export default Login;
