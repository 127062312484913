import React, { useState, useEffect } from 'react';
import {
  AppLayout,
  Header,
  Box,
  Link,
  DateRangePicker,
} from '@cloudscape-design/components';
import Breadcrumb from '../../components/Breadcrumbs';
import KPISection from '../../components/KpiSection';
import CustomLineChart from '../../components/LineChartSection';
import Navigation from '../../components/Navigation';
import { apiUrls } from "../../utils/apiUrls";
import { callAPI } from "../../utils/apiUtils";

function Dashboard() {
  const [value, setValue] = useState({
    startDate: new Date(Date.now() - 6 * 24 * 60 * 60 * 1000).toISOString().split('T')[0], // Start date (7 days ago)
    endDate: new Date().toISOString().split('T')[0], // Today's date
  });
  const [kpiData, setKpiData] = useState([]);
  const [lineChartData, setLineChartData] = useState([]);

  // Function to fetch data from the API
  const fetchData = async (dateRange) => {
    try {
      const reqData = { startDate: dateRange.startDate, endDate:dateRange.endDate}
      const response = await callAPI(apiUrls.ADMINREGISTRAIONDATA, reqData, 'GET');
      // const response = await callAPI(
      //   `/api/data?startDate=${dateRange.startDate}&endDate=${dateRange.endDate}`
      // );
      const data = response.data.regResult
      console.log(data)
      // Update KPI and Line Chart data dynamically
      setKpiData(data.kpiData || []);
      setLineChartData(data.lineChartData || []);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  // Call API on page load for the last 7 days
  useEffect(() => {
    fetchData(value);
  }, []);

  // Handle date apply
  const handleDateApply = ({ detail }) => {
    console.log("Date detail", detail)
    setValue(detail.value);
    fetchData(detail.value);
  };

  return (
    <AppLayout
      navigation={<Navigation />}
      content={
        <Box padding={{ vertical: 'l', horizontal: 'l' }}>
          <Header variant="h1">
            Registrations{' '}
            <Link variant="info" href="#">
              Info
            </Link>
          </Header>
          <Breadcrumb />

          <Box margin={{ bottom: 'm' }} style={{ width: 'auto', minWidth: '600px' }}>
            <DateRangePicker
              value={value}
              onChange={handleDateApply} // Trigger API call on date apply
              placeholder="Select a date range"
              relativeOptions={[
                {
                  key: 'last-7-days',
                  amount: 7,
                  unit: 'day',
                  label: 'Last 7 days',
                },
                {
                  key: 'last-30-days',
                  amount: 30,
                  unit: 'day',
                  label: 'Last 30 days',
                },
              ]}
              i18nStrings={{
                relativeRangeSelectionHeading: 'Relative range',
                absoluteRangeSelectionHeading: 'Absolute range',
                relativeRangeOptionLabel: ({ amount, unit }) =>
                  `${amount} ${unit}${amount > 1 ? 's' : ''}`,
                formatRelativeRange: ({ amount, unit }) =>
                  `Last ${amount} ${unit}${amount > 1 ? 's' : ''}`,
                customRelativeRangeOptionLabel: 'Custom range',
                customRelativeRangeOptionDescription:
                  'Define a custom range of days, weeks, or months.',
                customRelativeRangeDurationLabel: 'Duration',
                customRelativeRangeUnitLabel: 'Unit',
                cancelButtonLabel: 'Cancel',
                applyButtonLabel: 'Apply',
                dateRangePickerAriaLabel: 'Date range picker',
                startDateLabel: 'Start date',
                endDateLabel: 'End date',
                todayAriaLabel: 'Today',
              }}
            />
          </Box>

          <KPISection kpiData={kpiData} />
          <CustomLineChart lineChartData={lineChartData} />
        </Box>
      }
    />
  );
}

export default Dashboard;
