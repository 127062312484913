import React from 'react';
import { Container, Header } from '@cloudscape-design/components';

function TokenInfo() {
  return (
    <Container header={<Header>Tokens Sent/Received</Header>}>
      <p>
        Tokens sent: Bucket counts can consist only of lowercase letters, numbers, dots, and hyphens...
      </p>
    </Container>
  );
}

export default TokenInfo;
