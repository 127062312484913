import React from 'react';
import { Box, Input, Select, ColumnLayout, StatusIndicator } from '@cloudscape-design/components';

function UserInputSection({ userExist, onUserChange }) {
  return (
    <Box margin={{ bottom: 'l' }}>
      <ColumnLayout columns={4} variant="text-grid">
        <Input
          placeholder="Enter User ID or Email ID"
          onChange={(e) => onUserChange(e.target.value)}
        />
        {!userExist && <StatusIndicator type="error">User does not exist.</StatusIndicator>}
        <Input type="date" placeholder="Start Date" />
        <Input type="date" placeholder="End Date" />
        <Select
          selectedOption={{ label: 'Select Service', value: '' }}
          options={[
            { label: 'Chat GPT', value: 'chatGPT' },
            { label: 'Eleven Labs', value: 'elevenLabs' },
            { label: 'Presenter', value: 'presenter' },
          ]}
        />
      </ColumnLayout>
    </Box>
  );
}

export default UserInputSection;
