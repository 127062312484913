// src/components/ErrorMessage.js
import React from 'react';
import { useError } from '../contexts/ErrorContext';
import './ErrorMessage.css';  // You can add CSS for error styling

const ErrorMessage = () => {
  const { error } = useError();

  if (!error) return null;

  return (
    <div className="error-container">
      <p>{error}</p>
    </div>
  );
};

export default ErrorMessage;
