import React from 'react';
import { Box, Link } from '@cloudscape-design/components';

function Breadcrumb() {
  return (
    <Box margin={{ top: 's', bottom: 'm' }}>
      <Link variant="secondary">Users</Link> {'>'} Registrations
    </Box>
  );
}

export default Breadcrumb;
