import React from 'react';
import { Container, ColumnLayout, Box } from '@cloudscape-design/components';

function KPISection({ kpiData }) {
  return (
    <Container header={<h2>Registrations</h2>}>
      <ColumnLayout columns={5} variant="text-grid">
        {kpiData.map((kpi, index) => (
          <Box
            key={index}
            textAlign="center"
            padding="m"
            borderRadius="medium"
            backgroundColor="lightgray"
            boxShadow="light"
          >
            <Box fontWeight="bold">{kpi.label}</Box>
            <Box fontSize="heading-l" fontWeight="bold" color={kpi.color}>
              {kpi.value}
            </Box>
          </Box>
        ))}
      </ColumnLayout>
    </Container>
  );
}

export default KPISection;
