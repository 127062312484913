import React from 'react';
import { Container, Table, Box, Badge, Link, Header } from '@cloudscape-design/components';

function TableSection({ title, columns, items, footer }) {
  return (
    <Container header={<Header>{title}</Header>}>
      <Table columnDefinitions={columns} items={items} footer={footer} />
    </Container>
  );
}

export default TableSection;
