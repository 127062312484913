import React from 'react';
import { SideNavigation } from '@cloudscape-design/components';
import { useLocation } from 'react-router-dom';

function Navigation() {
  const location = useLocation();
  const items = [
    { type: 'link', text: 'Dashboard', href: '/dashboard' },
    { type: 'link', text: 'Users', href: '/useraudit' },
    { type: 'link', text: 'Reports', href: '/reports' },
    { type: 'link', text: 'Settings', href: '/settings' },
  ];

  return (
<SideNavigation
  header={{ text: 'Admin Panel', href: '/dashboard' }}
  items={[
    { type: 'link', text: 'Dashboard', href: '/dashboard' },
    { type: 'link', text: 'Users', href: '/useraudit' },
    { type: 'link', text: 'Reports', href: '/reports' },
    { type: 'link', text: 'Settings', href: '/settings' },
  ]}
  activeHref={location.pathname} // This dynamically highlights the active tab
  style={{ width: '250px' }}
/>

  );
}

export default Navigation;
