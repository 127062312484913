import React from 'react';
import { Box, LineChart } from '@cloudscape-design/components';

function CustomLineChart({ lineChartData }) {
  return (
    <Box margin={{ top: 'l' }}>
      <LineChart
        series={lineChartData}
        xDomain={['x1', 'x2', 'x3']}
        yDomain={[0, 50]}
        ariaLabel="Registrations chart"
        i18nStrings={{
          xAxisLabel: 'X-axis',
          yAxisLabel: 'Y-axis',
          legendAriaLabel: 'Legend',
          detailPopoverAriaLabel: 'Data point',
        }}
        height={300}
      />
    </Box>
  );
}

export default CustomLineChart;
