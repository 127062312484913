import React, { useState } from 'react';
import { AppLayout, Header, Box } from '@cloudscape-design/components';
import Navigation from '../../components/Navigation';
import TokenInfo from '../../components/TokenInfo';
import TableSection from '../../components/TableSection';
import UserInputSection from '../../components/UserInputSection';

function UserAudit() {
  const [userExist, setUserExist] = useState(false);

  const chatGPTData = [
    { dateTime: '01 Nov 2024, 12:00 PM', tokensSent: 35, tokensReceived: 550 },
    { dateTime: '01 Nov 2024, 12:00 PM', tokensSent: 240, tokensReceived: 670 },
    { dateTime: '01 Nov 2024, 12:00 PM', tokensSent: 500, tokensReceived: 900 },
  ];

  const elevenLabsData = [...chatGPTData];
  const presenterData = [
    { dateTime: '01 Nov 2024, 12:00 PM', status: 'Created', audioLength: 'Kunal Gaur' },
    { dateTime: '01 Nov 2024, 12:00 PM', status: 'Deleted', audioLength: 'Reetesh Gondal' },
    { dateTime: '01 Nov 2024, 12:00 PM', status: 'Created', audioLength: 'Kunal Gaur Accent' },
  ];

  return (
    <AppLayout
      navigation={<Navigation />}
      content={
        <Box padding={{ vertical: 'l', horizontal: 'l' }}>
          <Header variant="h1">User GPT/11L Audit</Header>

          {/* User Input Section */}
          <UserInputSection userExist={userExist} onUserChange={(value) => setUserExist(value === 'valid@example.com')} />

          {/* Token Info */}
          <TokenInfo />

          {/* Tables */}
          <TableSection
            title="Chat GPT"
            columns={[
              { header: 'Date/Time', cell: (item) => item.dateTime },
              { header: 'Tokens Sent', cell: (item) => item.tokensSent },
              { header: 'Tokens Received', cell: (item) => item.tokensReceived },
              { header: 'View', cell: () => <a href="#">View Details</a> },
            ]}
            items={chatGPTData}
            footer={<Box textAlign="right"><strong>Total:</strong> 775 Tokens Sent, 2120 Tokens Received</Box>}
          />

          <TableSection
            title="Eleven Labs"
            columns={[
              { header: 'Date/Time', cell: (item) => item.dateTime },
              { header: 'Tokens Sent', cell: (item) => item.tokensSent },
              { header: 'Audio Length Received', cell: (item) => `${item.tokensReceived} Seconds` },
            ]}
            items={elevenLabsData}
          />

          <TableSection
            title="Eleven Labs - Presenter"
            columns={[
              { header: 'Date/Time', cell: (item) => item.dateTime },
              { header: 'Presenter Status', cell: (item) => <strong>{item.status}</strong> },
              { header: 'Audio Length Received', cell: (item) => item.audioLength },
            ]}
            items={presenterData}
          />
        </Box>
      }
    />
  );
}

export default UserAudit;
